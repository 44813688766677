.CTA {
  width: 100%;
  display: flex;
  padding: 2em;
  margin: 2em 0;
  flex-direction: column;
  align-items: center;
  background: #2196f3;
  color: #fff; }
  .CTA_feedback {
    margin: 0.5em;
    padding: 0.5em;
    text-align: center;
    font-weight: bold;
    width: 94%;
    font-size: 0.9em; }
  .CTA a {
    text-decoration: none;
    color: #fff;
    font-weight: bold; }
  .CTA_input_title {
    padding: 0.3em 0;
    font-size: 0.8em; }
  @media screen and (max-width: 420px) {
    .CTA {
      padding: 1rem .8rem;
      margin: 2rem 0; } }
  @media screen and (max-width: 500px) {
    .CTA h3 {
      font-size: 2rem; } }
  .CTA .CTA_title {
    text-align: center; }
    @media screen and (max-width: 776px) {
      .CTA .CTA_title {
        max-width: 470px; } }
    .CTA .CTA_title p {
      margin-top: .6rem; }
  .CTA .CTAWrapper {
    margin-top: 30px; }
  .CTA .CTAWrapper_row {
    display: flex; }
    @media screen and (max-width: 776px) {
      .CTA .CTAWrapper_row {
        flex-direction: column;
        align-items: center; } }
    .CTA .CTAWrapper_row .input-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: .8rem;
      margin: 1rem 0 0.5rem 0; }
    .CTA .CTAWrapper_row > div {
      margin-right: 20px; }
      @media screen and (max-width: 776px) {
        .CTA .CTAWrapper_row > div {
          margin-right: 0;
          margin-bottom: 20px; } }
      .CTA .CTAWrapper_row > div:last-child {
        margin-right: 0; }
        @media screen and (max-width: 776px) {
          .CTA .CTAWrapper_row > div:last-child {
            margin-bottom: 0; } }
    .CTA .CTAWrapper_row input[type="text"] {
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid rgba(31, 32, 65, 0.25); }
      @media screen and (max-width: 776px) {
        .CTA .CTAWrapper_row input[type="text"] {
          width: 280px; } }
    .CTA .CTAWrapper_row .notValid input {
      border-color: red !important;
      box-shadow: 0 0px 3px #ff000085;
      color: red; }
      .CTA .CTAWrapper_row .notValid input:focus {
        outline: none !important; }
    .CTA .CTAWrapper_row.sendButton_row {
      flex-direction: column;
      align-items: center;
      margin-top: 20px; }
      .CTA .CTAWrapper_row.sendButton_row .Copyright_wrapper {
        display: flex;
        align-items: flex-start;
        font-size: .8rem;
        margin-top: 10px; }
        @media screen and (max-width: 776px) {
          .CTA .CTAWrapper_row.sendButton_row .Copyright_wrapper {
            max-width: 300px; } }
        @media screen and (max-width: 500px) {
          .CTA .CTAWrapper_row.sendButton_row .Copyright_wrapper {
            justify-content: center;
            text-align: center; } }
        .CTA .CTAWrapper_row.sendButton_row .Copyright_wrapper input {
          margin-top: 2px;
          margin-right: 10px; }
          @media screen and (max-width: 500px) {
            .CTA .CTAWrapper_row.sendButton_row .Copyright_wrapper input {
              margin-right: 0px; } }
      .CTA .CTAWrapper_row.sendButton_row .btn {
        color: white;
        background: #2da562;
        box-shadow: 0.14em 0.2em 0 #1a5634; }
        .CTA .CTAWrapper_row.sendButton_row .btn:hover {
          background: #2da562;
          box-shadow: 0.08em 0.13em 0 #1a5634; }
